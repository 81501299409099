<template>
  <v-container fluid class="ma-4 pr-8">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Cadastro de Tabela de Procedimento</h1>
        <h2 class="secondary--text font-weight-regular">Pesquisa</h2>
      </v-col>
    </v-row>
    <v-form ref="formSearch" lazy-validation>
      <v-row class="mt-10 align-center">
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Operadora</label>
          <v-autocomplete
            :items="carriers"
            item-text="xipp_commercial_name"
            item-value="xipp_commercial_name"
            class="mt-2"
            placeholder="Selecione a operadora"
            v-model="search.carrier"
            :loading="isLoadingCarrier"
            outlined
            clearable
            :disabled="isLoadingCarrier"
            color="textPrimary"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Código Original</label>
          <v-text-field
            v-model="search.originalCode"
            class="mt-2"
            placeholder="Informe o código"
            clearable
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Descrição Original</label>
          <v-text-field
            v-model="search.originalDescription"
            class="mt-2"
            placeholder="Informe a descrição"
            clearable
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Código TUSS</label>
          <v-text-field
            v-model="search.tussCode"
            class="mt-2"
            placeholder="Informe o código"
            clearable
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Descrição TUSS</label>
          <v-text-field
            v-model="search.tussDescription"
            class="mt-2"
            placeholder="Informe a descrição"
            clearable
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Capítulo</label>
          <v-text-field
            v-model="search.chapter"
            class="mt-2"
            clearable
            placeholder="Informe o capítulo"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Grupo</label>
          <v-text-field
            v-model="search.group"
            class="mt-2"
            clearable
            placeholder="Informe o grupo"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Subgrupo</label>
          <v-text-field
            v-model="search.subGroup"
            class="mt-2"
            clearable
            placeholder="Informe o subgrupo"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Grupo de Procedimento</label>
          <v-text-field
            v-model="search.procedureGroup"
            class="mt-2"
            clearable
            placeholder="Informe o grupo"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Rol</label>
          <v-text-field
            v-model="search.rol"
            class="mt-2"
            clearable
            placeholder="Informe o Rol"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            @click.prevent="filter()"
          >
            Localizar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            @click="
              $router.push({
                name: 'ProcedureRegisterForm',
                query: { isInsert: true },
              })
            "
          >
            <v-icon left> fas fa-plus </v-icon>
            Novo Procedimento
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="results.content"
          :loading="isLoading"
          hide-default-footer
          item-key="descOriginal"
          :items-per-page="10"
          :page="page"
        >
          <template v-slot:[`item.tabela`]="{ item }">
            {{ item.tabela }}
          </template>
          <template v-slot:[`item.originalCode`]="{ item }">
            {{ item.cdOriginal }}
          </template>
          <template v-slot:[`item.originalDescription`]="{ item }">
            {{ item.descOriginal }}
          </template>
          <template v-slot:[`item.attachments`]="{ item }">
            <v-menu open-on-hover bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                  <v-icon color="textPrimary" size="20"
                    >fas fa-ellipsis-v</v-icon
                  >
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="onClickDetails(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon size="20" color="textPrimary">fas fa-search</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="mt-1">
                    <v-list-item-title>{{ "Ver Cadastro" }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="onClickEdit(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon size="20" color="textPrimary">fas fa-edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="mt-1">
                    <v-list-item-title>{{ "Editar" }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            color="textPrimary"
            v-model="page"
            :length="results.totalElements ? Math.ceil(results.totalElements / 10) : 0"
            :total-visible="9"
          />
        </div>
      </v-col>
    </v-row>
    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import CarrierService from '@/services-http/contract/CarrierService';
import ProcedureControlCodeService from '@/services-http/analytics/ProcedureControlCodeService';

export default {
  name: 'ProcedureRegisterSearch',
  data: () => ({
    overlay: false,
    headers: [
      {
        text: 'Operadora',
        value: 'tabela',
        align: 'center',
      },
      {
        text: 'Código Original',
        value: 'originalCode',
        align: 'center',
      },
      {
        text: 'Descrição Original',
        value: 'originalDescription',
        align: 'center',
      },
      {
        text: 'Ações',
        value: 'attachments',
        align: 'center',
        sortable: false,
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
        width: '3%',
      },
    ],

    search: {
      chapter: null,
      originalCode: null,
      originalDescription: null,
      tussCode: null,
      tussDescription: null,
      group: null,
      subGroup: null,
      procedureGroup: null,
      carrier: null,
      rol: null,
    },
    payload: {},
    isLoading: false,
    isLoadingCarrier: false,
    results: [],
    carriers: [],
    expanded: [],
    page: 1,
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),
  methods: {
    async filter() {
      if (!this.$refs.formSearch.validate()) {
        return;
      }
      const payload = {
        capitulo: this.search.chapter,
        cdOriginal: this.search.originalCode,
        cdTuss: this.search.tussCode,
        descOriginal: this.search.originalDescription,
        descTuss: this.search.tussDescription,
        grupo: this.search.group,
        subgrupo: this.search.subGroup,
        grupoProcedimento: this.search.procedureGroup,
        tabela: this.search.carrier,
        rol: this.search.rol,
      };

      const filters = Object.keys(payload);
      const filtersMapped = filters.map((filter) => (payload[filter] !== null ? `${filter}=${payload[filter]}` : null));

      const validFilters = filtersMapped?.filter((filter) => filter !== null);
      const queryString = !this.isEmptyFilters()
        ? `?${validFilters.join('&')}`
        : '';

      await this.ProcedureControlCodeService.FindAllByFilters(queryString).then((response) => {
        this.results = response.data;
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar buscar os procedimentos');
      });

      this.cleanFilters();
    },

    onClickDetails(item) {
      sessionStorage.setItem('procedure', JSON.stringify(item));
      this.$router.push({ name: 'ProcedureRegisterForm' });
    },

    onClickEdit(item) {
      sessionStorage.setItem('procedure', JSON.stringify(item));
      this.$router.push({
        name: 'ProcedureRegisterForm',
        query: { isEdit: true },
      });
    },

    onClickCancel() {
      this.$router.push({ name: 'HomeAllMenus' });
    },

    isEmptyFilters() {
      return !this.search;
    },

    cleanFilters() {
      this.search = {
        chapter: null,
        originalCode: null,
        originalDescription: null,
        tussCode: null,
        tussDescription: null,
        group: null,
        subGroup: null,
        procedureGroup: null,
        carrier: null,
        rol: null,
      };
    },

    async loadCarrierService() {
      this.isLoadingCarrier = true;
      await this.carrierService.FindAll().then((response) => {
        if (response && response.data) {
          this.isLoadingCarrier = false;
          this.carriers = response.data;
          sessionStorage.setItem('carrierList', JSON.stringify(this.carriers));
        }
      });
    },
  },
  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.carrierService = new CarrierService();
    this.ProcedureControlCodeService = new ProcedureControlCodeService();
    if (sessionStorage.getItem('carrierList')) {
      this.carriers = JSON.parse(sessionStorage.getItem('carrierList'));
    } else {
      await this.loadCarrierService();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== '/procedure-register-form') {
      sessionStorage.removeItem('procedure');
      sessionStorage.removeItem('carrierList');
    }
    next();
  },
  customizeSnackbarMessage(type, text) {
    this.snackbar.show = true;
    this.snackbar.type = type;
    this.snackbar.text = text;
  },
};
</script>
