/* eslint-disable */
import HttpService from '../HttpService';

export default class ProcessControllService {
  constructor() {
    this._httpService = new HttpService('/analytics/procedure_code');
  }

  async FindAllByFilters(params) {
    return this._httpService.get(`/find_all_by_filters/${params}`);
  }

  async Insert(data) {
    return this._httpService.put(`/insert/${data.table}`, data);
  }

  async Update(data) {
    return this._httpService.put(`/update/${data.tabela}/code/${data.cdOriginal}/description/${data.descOriginal}`, data.payload);
  }
}
